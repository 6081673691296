<template>
    <div>
        <card-header title="New Flight" icon="fa-plus"/>

        <card-body>
            <flight-form :flight="newFlight" :registration="registration" :itinerary="itinerary"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newFlight.arranged_by || !newFlight.depart_airport || !newFlight.arrive_airport || !newFlight.depart_date || !newFlight.depart_time || !newFlight.arrive_date || !newFlight.arrive_time || !newFlight.flight_number" @click="submit"><i class="fas fa-plus mr-3"></i>Save Flight</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardBody from '../../TIER/components/CardBody';
    import CardFooter from '../../TIER/components/CardFooter';
    import FlightForm from './FlightForm';

    import {client as http} from '../../http_client';
    import {mapActions} from "vuex";

    export default {
        props: ['card', 'props'],
        components: {CardFooter, CardBody, CardHeader, FlightForm},
        data: function () {
            return {
                registration: {
                    user: {}
                },
                newFlight: {
                    depart_airport: null,
                    depart_airport_id: null,
                    arrive_airport: null,
                    arrive_airport_id: null
                },
                itinerary: []
            };
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadRegistration: function() {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId, {force: true}).then(response => {
                    this.registration = response.data;
                    this.newFlight.applies_to = (this.registration.second_user) ? 'both' : 'primary';

                    http.get('/api/registrations/' + this.props.registrationId + '/itinerary', {force: true}).then(response => {
                        this.itinerary = response.data.days;
                        this.$emit('loading', false);
                    });
                });
            },
            submit() {
                this.$emit('loading', true);
                http.post('/api/registrations/' + this.props.registrationId + '/flights', this.newFlight, {force: true}).then(response => {
                    this.updateTasks();
                    this.$reloadCard('registrations');
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});
                    this.$reloadCard('flights', {registrationId: this.props.registrationId});
                    this.$reloadCard('tasks');
                    this.$reloadCard('task-details', {type: 'flights'});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        mounted() {
            this.loadRegistration();
            this.newFlight.registration_id = this.props.registrationId;
            this.$saveAlert(true, 'Are you done creating this new flight? Unsaved changes will be lost.');
        }
    };
</script>
